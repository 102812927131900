@import '../../scss/variables/variables.scss';
@import '../../scss/variables/mixins.scss';

.about {
  position: relative;
  padding: 45vh 0 0;
  // padding: 273px 0 0;
  @include breakpoint(md) {
    padding: 493px 0 80px;
  }
  @include breakpoint(lg) {
    padding: 583px 0 80px;
  }
}

.about__img {
  position: relative;
  z-index: -1;
}

.about__second {
  margin-top: 40px;

  @include breakpoint(md) {
    margin-top: 60px;
  }

  @include breakpoint(lg) {
    margin-top: 130px;
  }
}

.about__third {
  margin-top: 30px;

  @include breakpoint(lg) {
    margin-top: 80px;
  }
}

.about__strategy {
  margin-top: 20px;

  @include breakpoint(md) {
    margin-top: 70px;
  }
}

.about__strategy__title {
  @include breakpoint(md) {
    margin-bottom: 15px;
  }
}

.about__design {
  margin-top: 15px;

  @include breakpoint(md) {
    margin-top: -40px;
  }
}

.about__campaign {
  margin-top: 20px;

  @include breakpoint(md) {
    margin-top: -40px;
  }
}

.hash-list {
  line-height: 1.2 !important;
  margin-top: 5px;
}

.hash-list__item + .hash-list__item {
  margin-top: 3px;
}

.logo-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 500px;
  margin: 20px auto 0;

  &::after {
    content: '';
    flex: auto;
  }

  @include breakpoint(md) {
    max-width: 800px;
  }
}

.logo-list__item {
  flex: 0 0 25%;
  text-align: center;
  margin-top: 20px;

  img {
    display: inline;
  }

  @include breakpoint(md) {
    flex: 0 0 20%;
    margin-top: 50px;
  }
}

.about__cta {
  text-align: right;
  margin-right: 23px;

  @include breakpoint(md) {
    text-align: left;
    margin-right: 0;
    margin-top: -300px;
    margin-bottom: 300px;
  }
}

.about__cta__copy,
.about__email {
  @include breakpoint(md) {
    font-size: 20px !important;
    font-size: 2rem !important;
  }
}

.about__email {
  text-align: right;
  margin-top: 15px;

  @include breakpoint(md) {
    text-align: left;
    color: $blue-1;
    margin-top: 0;

    &:visited {
      color: $blue-1;
    }
    &:hover,
    &.is-hovered {
      color: $black;
    }
  }
}

.about--no-positions {
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    justify-content: center;
  }
}

.career-list {
  @include breakpoint(md) {
    margin-top: 30px;
  }
}

.career-list__item + .career-list__item {
  margin-top: 5px;

  @include breakpoint(md) {
    margin-top: 20px;
  }
}
