@import "../variables/variables.scss";

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page { flex: 1 0 auto; }

  .footer {
    flex-shrink: 0;
    padding-bottom: 30px;
  }
}
