@import '../variables/variables.scss';
@import '../variables/mixins.scss';

%font-backlash {
  font-family: $font-backlash;
}
// %font-backlash-black          { font-family: $font-backlash-black; }
// %font-backlash-black-italic   { font-family: $font-backlash-black-italic; }
// %font-backlash-bold           { font-family: $font-backlash-bold; }
// %font-backlash-bold-italic    { font-family: $font-backlash-bold-italic; }
// %font-backlash-book           { font-family: $font-backlash-book; }
// %font-backlash-book-italic    { font-family: $font-backlash-book-italic; }
// %font-backlash-medium         { font-family: $font-backlash-medium; }
// %font-backlash-medium-italic  { font-family: $font-backlash-medium-italic; }

// %font-fabada-bold             { font-family: $font-fabada-bold; }
// %font-fabada-bold-italic      { font-family: $font-fabada-bold-italic; }
// %font-fabada-light            { font-family: $font-fabada-light; }
// %font-fabada-light-italic     { font-family: $font-fabada-light-italic; }
// %font-fabada-regular-italic   { font-family: $font-fabada-regular-italic; }
%font-fabada {
  font-family: $font-fabada;
}

%font-space-grotesk-bold {
  font-family: $font-space-grotesk-bold;
}
%font-space-grotesk-light {
  font-family: $font-space-grotesk-light;
}
%font-space-grotesk-medium {
  font-family: $font-space-grotesk-medium;
}
%font-space-grotesk-regular {
  font-family: $font-space-grotesk-regular;
}
%font-space-grotesk-semibold {
  font-family: $font-space-grotesk-semibold;
}

.type--a0,
.type--a1,
.type--a2,
.type--a32,
.type--a33,
.type--a34,
.type--a4,
.type--a51,
.type--a6 {
  @extend %font-space-grotesk-medium;
  line-height: 1;
}

.type--a1 {
  font-size: 18vw;
  @include breakpoint(sm) {
    @include font-size(100);
  }
  @include breakpoint(md) {
    @include font-size(120);
  }
  @include breakpoint(lg) {
    @include font-size(144);
  }
}
.type--a2 {
  @include font-size(40);

  @include breakpoint(md) {
    font-size: 6.7vw;
  }

  @include breakpoint(lg) {
    @include font-size(80);
  }
}
.type--a31 {
  @extend %font-space-grotesk-regular;
  @include font-size(30);

  @include breakpoint(md) {
    @include font-size(40);
  }
}
.type--a32 {
  @include font-size(15);
  line-height: 1.1;
  @include breakpoint(md) {
    @include font-size(40);
  }
}
.type--a33 {
  line-height: 1.3;
  @include font-size(15);
  @include breakpoint(md) {
    @include font-size(28);
  }
}
.type--a34 {
  line-height: 1.3;
  @include font-size(15);
  @include breakpoint(md) {
    @include font-size(19);
  }
}
.type--a35 {
  @extend %font-space-grotesk-regular;
  line-height: 1.3;
  @include font-size(25);
  @include breakpoint(md) {
    @include font-size(30);
  }
}
.type--a4 {
  @extend %font-space-grotesk-bold;
  font-weight: bold;
  @include font-size(20);

  @include breakpoint(md) {
    @include font-size(40);
  }
}
.type--a51 {
  font-size: 13vw;
  @include breakpoint(sm) {
    @include font-size(72);
  }
  @include breakpoint(md) {
    font-size: 9.1vw;
  }
  @include breakpoint(lg) {
    @include font-size(144);
  }
}

.type--a52 {
  @extend %font-space-grotesk-regular;
  font-size: 13vw;
  @include breakpoint(sm) {
    @include font-size(72);
  }
  @include breakpoint(md) {
    font-size: 9.1vw;
  }
  @include breakpoint(lg) {
    @include font-size(80);
  }
}

.type--a6 {
  line-height: 1.5;
  @include font-size(12);
}

.type--b1,
.type--b2,
.type--b3 {
  @extend %font-fabada;
}

.type--b1 {
  line-height: 1.5;
  @include font-size(10);

  @include breakpoint(md) {
    @include font-size(12);
  }
}

.type--b2 {
  @include font-size(10);
}

.type--b3 {
  line-height: 1.75;
  @include font-size(9);

  @include breakpoint(md) {
    @include font-size(12);
  }
}

.type--italic {
  font-style: italic;
}
.type--bold {
  font-weight: 800;
}

.type--blue-1 {
  color: $blue-1;
}
.type--navy-1 {
  color: $navy-1;
}
.type--red-1 {
  color: $red-1;
}
.type--black {
  color: $black;
}
.type--white {
  color: $white;
}

.type--block {
  display: block;
}
.type--inline {
  display: inline;
}
.type--inline-block {
  display: inline-block;
}

.type--center {
  text-align: center;
}
.type--right {
  text-align: right;
}

.type--underline {
  text-decoration: underline;
}

.type--uppercase {
  text-transform: uppercase;
}
.type--lowercase {
  text-transform: lowercase;
}

.dark-mode [class*='type--'] {
  color: $white;
}
