// Use sass variables only when needing to programmatically change
// the value, such as a @for loop.
// The variables file will need to be imported in the component
// to be accessible `@import "~scss/variables.scss";`
//  ======================================================================================================
//
//  Variables
//
//  ======================================================================================================

//  Responsive Breakpoints
//  --------------------------------------------------
  $break-xs:           350px  !default;
  $break-sm:           580px  !default;
  $break-md:           768px  !default;
  $break-lg:           1024px !default;
  $break-xl:           1280px !default;

//  Color
//  --------------------------------------------------
  $blue-1:    rgba(0, 0, 255, 1);
  $pink-1:    rgba(227, 209, 213, 1); // #e3d1d5
  $pink-2:    rgba(255, 249, 249, 1); // #fff9f9
  $red-1:     rgba(255, 45, 59, 1); // #ff2d3b
  $toledo-1:  rgba(46, 31, 39, 1); // #2e1f27
  $navy-1:    rgba(45, 48, 71, 1); // #2d3047


  $grey-1: rgba(245, 245, 245, 1); // #f5f5f5
  $grey-2: rgba(151, 151, 151, 1); // #979797

  // Neutrals
  $white:              rgba(255,255,255,1) !default; // white
  $black:              rgba(0,  0,  0,  1) !default; // black
  $green: rgb(0,255,0);

//  Default Color Settings
//  --------------------------------------------------
  $body-bg:                 $grey-1     !default;
  $default-txt-color:       $black      !default;
  $placeholder-txt-color:   $black      !default;

  $hr-color:                $black      !default;
  $highlight:               $blue-1     !default;
  $highlight-text:          $white      !default;

//  Font Families
//  --------------------------------------------------
  $font-backlash:  Backlash, cursive;
  // $font-backlash-black:          'BacklashBlack', cursive, Verdana, sans-serif !default;
  // $font-backlash-black-italic:   'Backlash-BlackItalic', cursive, Verdana, sans-serif !default;
  // $font-backlash-bold:           'Backlash-Bold', cursive, Verdana, sans-serif !default;
  // $font-backlash-bold-italic:    'Backlash-BoldItalic', cursive, Verdana, sans-serif !default;
  // $font-backlash-book:           'Backlash-Book', cursive, Verdana, sans-serif !default;
  // $font-backlash-book-italic:    'Backlash-BookItalic', cursive, Verdana, sans-serif !default;
  // $font-backlash-medium:         'Backlash-Medium', cursive, Verdana, sans-serif !default;
  // $font-backlash-medium-italic:  'Backlash-MediumItalic', cursive, Verdana, sans-serif !default;

  // $font-fabada-bold:             'Fabada-Bold', cursive, Verdana, sans-serif !default;
  // $font-fabada-bold-italic:      'Fabada-BoldItalic', cursive, Verdana, sans-serif !default;
  // $font-fabada-light:            'Fabada-Light', cursive, Verdana, sans-serif !default;
  // $font-fabada-light-italic:     'Fabada-LightItalic', cursive, Verdana, sans-serif !default;
  // $font-fabada-regular-italic:   'Fabada-RegularItalic', cursive, Verdana, sans-serif !default;
  $font-fabada:   Fabada, cursive, Verdana, sans-serif !default;
  $font-space-grotesk-bold:     SpaceGrotesk-Bold,     Verdana, sans-serif !default;
  $font-space-grotesk-light:    SpaceGrotesk-Light,    Verdana, sans-serif !default;
  $font-space-grotesk-medium:   SpaceGrotesk-Medium,   Verdana, sans-serif !default;
  $font-space-grotesk-regular:  SpaceGrotesk-Regular,  Verdana, sans-serif !default;
  $font-space-grotesk-semibold: SpaceGrotesk-SemiBold, Verdana, sans-serif !default;

  $font-bold:               $font-backlash, Helvetica, Arial, sans-serif !default;
  $font-regular:            $font-fabada, Helvetica, Arial, sans-serif !default;
  $font-light:              $font-backlash, Helvetica, Arial, sans-serif !default;

//  Font Stacks
//  --------------------------------------------------
  $font-family-default:     $font-regular        !default;
  $font-family-bold:        $font-bold           !default;
  $font-family-italic:      $font-regular        !default;

//  Type Sizes (use rem mixin to apply)
//  --------------------------------------------------
  $font-size-base:          16px                 !default;
  $font-size-root:          62.5%                !default;

//  Defaults
//  --------------------------------------------------
  $font-weight-default:     normal               !default;
  $line-height-base:        1.5                  !default;

//  Headings
//  --------------------------------------------------
  $headings-font-family:    $font-bold           !default;
  $headings-font-weight:    normal               !default;
  $headings-line-height:    $line-height-base    !default;
  $headings-font-color:     $black               !default;

//  Blockquotes
//  --------------------------------------------------
  $blockquote-font-family:  $font-family-default !default;
  $blockquote-txt-size:     $font-size-base      !default;
  $blockquote-txt-color:    $default-txt-color   !default;

//  Links
//  --------------------------------------------------
  $link-color:              $black               !default;
  $link-hover-color:        rgba($black, 0.7)    !default;

//  Forms
//  --------------------------------------------------
  $font-family-form:        $font-family-default !default;
  $font-family-label:       $font-family-default !default;

//  Animation
//  --------------------------------------------------
  $default-animation:       all 0.3s             !default;
  $default-animation-duration:  0.3s             !default;

//  Site Max Width
//  --------------------------------------------------
  $max-width:  1282px;
  $nav-height: 50px;
