@import '../../scss/variables/mixins.scss';

.studies {
  padding-top: 43vh;

  @include breakpoint(md) {
    padding-top: 450px;
  }
  @include breakpoint(lg) {
    padding-top: 530px;
  }
}

.studies-description {
  width: 72%;
}

.studies-rows {
  padding: 100px 0;
  margin-top: -25px;

  @include breakpoint(md) {
    margin-top: -50px;
  }

  & > .grid {
    &--flex-start,
    &--flex-end,
    &--flex-center,
    &--flex-normal {
      @include breakpoint-max(lg) {
        align-items: normal;
      }
    }

    .column {
      margin-top: 25px;

      @include breakpoint-max(lg) {
        align-self: normal !important;
      }

      @include breakpoint(md) {
        margin-top: 50px;
      }
    }
  }
}
