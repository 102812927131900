@import "../variables/mixins.scss";


//  ==========================================================================
//  Visibility Controllers
//  ==========================================================================
.is-visually-hidden {
  border: 0 !important;
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  left: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 1px !important;
}

.is-transparent { opacity: 0; }

.vertical-text {
  transform: rotate(-90deg) translateY(100%);
  transform-origin: bottom left;
}


.is-visible-lg--flex {
  display: none !important;
  @include breakpoint(lg) { display: flex !important; }
}

.is-hidden-lg {
  @include breakpoint(lg) { display: none; }
}

.is-hidden { display: none !important; }

.pointer--none { pointer-events: none; }
.pointer--auto { pointer-events: auto; }

// html.no-scroll,
// html.no-scroll body {
//   overflow: hidden;
// }

body.no-scroll {
  overflow: hidden;

  &.has-scrollbar { padding-right: 15px; }
}

.mt-50 { margin-top: 50px; }
