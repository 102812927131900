@import '../../scss/variables/variables.scss';
@import '../../scss/variables/mixins.scss';

.logo svg {
  overflow: visible !important;
}

.cls-1,
.cls-2 {
  fill: none;
  stroke: #ff2d3b;
  stroke-linecap: round;
  stroke-width: 1px;

  @include breakpoint(md) {
    stroke-width: 1.4px;
  }
}
.cls-1 {
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke-linejoin: round;
}
