@import "../../scss/variables/variables.scss";
@import "../../scss/variables/mixins.scss";

.video-wrapper {
  position: relative;
}

.video-wrapper__play {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
    height: 50px;

  }
}

.video-wrapper--playing .video-wrapper__play { opacity: 0; }
