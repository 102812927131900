@import "../../scss/variables/variables.scss";
@import "../../scss/variables/mixins.scss";

.pass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border: 1px solid $blue-1;
  border-radius: 10px;
  min-width: 300px;
  max-width: 700px;
  padding: 30px;
  transition: border-color $default-animation-duration ease-in-out;
}

.pass--error {
  border-color: $red-1;
  .pass__form__error { opacity: 1; }
}

.pass__form {
  label {
    display: block;
  }
}
.pass__form__input {
  margin-top: 5px;
  width: 75%;
  display: block;
  border: 1px solid $black;
  padding: 10px 15px;
  border-radius: 5px;
  min-width: 200px;
}

.pass__form__submit {
  margin-top: 10px;
  border: 1px solid $black;
  background-color: $white;
}

.pass__form__error {
  margin-top: 10px;
  opacity: 0;
  transition: opacity $default-animation-duration ease-in-out;
}
