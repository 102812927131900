@import '../../scss/variables/variables.scss';

.link {
  text-align: left;
  transition: color 200ms ease-in-out;
}

.link--black {
  color: $black;

  &:visited { color: $black; }
  &:hover,
  &.is-hovered { color: $blue-1; }
}

.link--blue-1 {
  color: $blue-1;

  &:visited { color: $blue-1; }
  &:hover,
  &.is-hovered { color: $black; }
}

.link--navy-1 {
  color: $navy-1;

  &:visited { color: $navy-1; }
  &:hover,
  &.is-hovered { color: $red-1; }
}
