@import '../../scss/variables/variables.scss';
@import '../../scss/variables/mixins.scss';

.nav-bar {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 9999;
}

body.has-scrollbar.no-scroll .nav-bar {
  padding-right: 15px;
}

.nav-bar__logo {
  transition: none;
  width: 30px;
  max-width: none;
  @include breakpoint(md) {
    width: 50px;
  }
}

.nav-bar__name {
  display: block;
  margin: 0 auto;

  img {
    transition: width 500ms ease-in-out;
    width: 2.7vh;
    margin: 0 auto;
    min-width: 16px;
    max-width: 30px;

    @include breakpoint(md) {
      width: 18px;
    }
  }
}

.nav-bar__instagram {
  margin-top: 50px;
}

// .nav-bar__slogan { min-width: 110px; }

.nav-bar__details {
  margin-top: 25px;

  & > *:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > * + * {
      margin-left: 20px;
    }
  }
}

.nav-bar__nav {
  transition: transform 400ms ease-in-out;
}

.nav-bar--partial,
.nav-bar--partial_to_normal {
  .nav-bar__first-row {
    height: 55vh;
  }
}

.nav-bar--partial {
  .nav-bar__details,
  .nav-bar__instagram {
    opacity: 0;
    transform: translateY(120px);
  }
}

.nav-bar--normal {
  transition: top 500ms ease-in-out, transform 500ms ease-in-out;
}

.nav-bar--partial,
.nav-bar--normal,
.nav-bar--reduced,
.nav-bar--show {
  top: 15px;
  transform: translateY(0);

  @include breakpoint(md) {
    top: 30px;
  }

  .nav-bar__instagram {
    margin-top: 10px;
  }
}

.nav-bar--reduced,
.nav-bar--show {
  transform: translateY(-145px);
  transition: all 500ms ease-in-out 300ms;

  @include breakpoint(md) {
    transform: translateY(-140px);
  }

  .nav-bar__nav {
    transition: transform 300ms ease-in-out 275ms;
  }

  .nav-bar__nav {
    transform: translateY(-30px);

    @include breakpoint(md) {
      transform: translateY(-46px);
    }

    @include breakpoint(lg) {
      transform: translateY(-123px);
    }
  }
}
